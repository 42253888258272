import React from 'react';
import {
  InputLabel,
  Select,
  FormHelperText,
  FormControl,
  MenuItem,
  makeStyles,
} from '@material-ui/core';

type OptionValue = {
  label: string;
  value: string | number;
};

interface InputSelectProps {
  label: string;
  name: string;
  control: any;
  value?: string | number;
  options: OptionValue[];
  helperText?: string;
  error?: boolean;
  className?: string;
  margin?: 'normal' | 'dense' | 'none';
  style?: string;
  fullWidth?: boolean;
  size?: 'small' | 'medium';
}

const useStyles = makeStyles({
  option: {
    '&:hover': {
      backgroundColor: '#f0f0f0',
      color: '#000',
    },
  },
});

const InputSelect: React.FC<InputSelectProps> = ({
  label,
  options,
  value,
  name,
  helperText,
  error,
  className,
  margin,
  style,
  fullWidth = true,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <FormControl
      margin={margin}
      fullWidth={fullWidth}
      error={error}
      className={className}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select {...rest} name={name} value={value} label={label}>
        <MenuItem value="">
          <em>DESMARCAR</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            value={option.value}
            key={option.value}
            className={classes.option}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default InputSelect;
